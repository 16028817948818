import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import assert from 'assert';
import {typeCheck} from 'type-check';
import ApiService, { ProductDatum } from '../../services/ApiService';
import { Product, ProductDetailItem, ProductFeed } from '@server/other/classes';

export interface ProductSliceState {
  isGettingFeed: boolean,
  isGettingProduct: boolean,
  feed: ProductFeed | null,
  product: ProductDatum | null,
  // products: {string?: ProductDatum},
}

const initialState: ProductSliceState = {
  isGettingFeed: false,
  isGettingProduct: false,
  feed: null,
  product: null,
  // products: {},
};

export const getProductFeed = createAsyncThunk(
  'products/getProductFeed', async (idToken: string) => {
    const result = await ApiService.getInstance()
      .getProductFeed();
    return result;
});

export const getProductbyId = createAsyncThunk(
  'products/getProductById', async (
    params: {
      productId: string,
    }
  ) => {
    const { productId } = params;
    assert(typeCheck('String', productId), 'productId must be string');
    const result = await ApiService.getInstance()
      .getProductById(productId);
    return result;
});

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: (create) => ({
    clearEditing: create.reducer((state) => {
      state.isGettingFeed = false;
    }),
  }),
  extraReducers: (builder) => {
    // ------------ Get Feed ---------------
    builder.addCase(getProductFeed.pending, (state, action) => {
      state.isGettingFeed = true;
    }).addCase(getProductFeed.fulfilled, (state, action) => {
      state.isGettingFeed = false;
      state.feed = action.payload;
      // const newProducts = {...state.products};
      // Object.values(action.payload).forEach((product) => {
      //   if (product.id) {
      //     newProducts[product.id] = product;
      //   }
      // });
      // state.products = newProducts;
    }).addCase(getProductFeed.rejected, (state, action) => {
      state.isGettingFeed = false;
    });

    // ------------ Get Product By ID ---------------
    builder.addCase(getProductbyId.pending, (state, action) => {
      state.isGettingProduct = true;
    }).addCase(getProductbyId.fulfilled, (state, action) => {
      state.isGettingProduct = false;
      state.product = action.payload;
    }).addCase(getProductbyId.rejected, (state, action) => {
      state.isGettingProduct = false;
    });

  },
  selectors: {
    selectFeed: (state) => state.feed,
    selectProduct: (state) => state.product,
  },
});

export const {
  selectFeed,
  selectProduct,
} = productsSlice.selectors;

export const {
} = productsSlice.actions;

export default productsSlice.reducer;