import { Card, Stack, Typography, Divider, Box, TextField, CircularProgress, debounce, Switch, FormControlLabel, IconButton, Button, List, ListItemIcon, ListItem, Grid, LinearProgress, Alert } from '@mui/material';
import ThemedPaper from '../core/ThemedPaper';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../state/store';
import { clearEditing, createProduct, editProduct, getCreationById, selectCreation, selectIsCreatingProduct, selectIsGettingCreation, selectIsUpdatingProduct } from '../../state/slices/creations';
import { userSlice } from '../../state/slices/user';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import { isEqual } from 'lodash'

import SortableList, { ActiveItem } from '../core/SortableList';
import { AnyObject } from '../../types';

import ProductItemEditor from './ProductItemEditor';
import { ProductContent, ProductDatum } from '../../services/ApiService';
import Paths from '../../paths';

const DEBOUNCE_WAIT_MS = 100;

const MAX_PRODUCT_NAME_LENGTH = 80;

const UsdFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function getItemFromState(
  state: ProductDatum, groupIndex: number, itemIndex: number,
): ProductContent {

  try {
    const item = state.content[groupIndex].content[itemIndex];
    return structuredClone(item);
  } catch (error) {
    console.error('getItemFromState', state, groupIndex, itemIndex);
    throw error;
  }
}

function setItemFromState(
  state: ProductDatum,
  groupIndex: number,
  itemIndex: number,
  newItem: ProductContent,
): ProductDatum {
  const clonedState = structuredClone(state);
  clonedState.content[groupIndex].content[itemIndex] = newItem;
  return clonedState;
}

type Props = {
  isEdit?: boolean,
};


function createNewProductData() {
  return {
    title: '[Untitled]',
    imageUrl: '',
    isPublished: false,
    content: [],
    monthyPrice: 9,
    currency: 'USD',
  };
}



const loadProduct = debounce((productId, dispatch) => {
  return dispatch(getCreationById({ productId }))
}, DEBOUNCE_WAIT_MS);

export default function ProductEditor(props: Props = {
  isEdit: false,
}) {

  const { isEdit } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const idToken = useAppSelector(userSlice.selectors.selectIdToken) ?? '';
  const product = useAppSelector(selectCreation);

  const isGettingCreation = useAppSelector(selectIsGettingCreation);
  const isUpdatingProduct = useAppSelector(selectIsUpdatingProduct);
  const isCreatingProduct = useAppSelector(selectIsCreatingProduct);

  const [activeItem, setActiveItem] = useState<ActiveItem|null>(null);

  const { productId } = useParams();

  const [
    editorState, setEditorState
  ] = useState<ProductDatum>(product ?? createNewProductData());

  useEffect(() => {
    if (!productId) {
      // We have a brand new product, creating for first time.
      dispatch(clearEditing());
    }
    if (isEdit && productId != null) {
      loadProduct(productId, dispatch);
    }
  }, [productId, dispatch, idToken, isEdit]);


  useEffect(() => {
    if (isEdit && product) {
      setEditorState(product);
    }
  }, [product, isEdit]);

  useEffect(() => {
    return function cleanup() {
      if (isEdit) {
        setEditorState(createNewProductData());
        dispatch(clearEditing());
      }
    };
  }, [isEdit, dispatch]);

  if (!idToken || isGettingCreation) {
    return <CircularProgress />;
  }

  if (isEdit && !product) {
    return (
      <>
      Loading Product
      <LinearProgress/>
      </>
    );
  }

  const isStateChanged = !isEqual(product, editorState);

  const isTitleValid = editorState.title != null &&
  editorState.title.length < MAX_PRODUCT_NAME_LENGTH &&
  editorState.title.length > 2;
  const isValid = isTitleValid && isStateChanged;


  // Set prices
  const stateClone = structuredClone(editorState);
  let isPriceChanged = false;
  if (editorState.monthlyPrice == null) {
    stateClone.monthlyPrice = 9_67;
    isPriceChanged = true;
  }
  if (editorState.coursePrice == null) {
    stateClone.coursePrice = 99_67;
    isPriceChanged = true;
  }
  if (isPriceChanged) {
    setEditorState(stateClone);
  }

  return (
    <Grid container  direction='row'
    justifyContent='center'
    alignItems='center'
    spacing={2}
    >
    {!activeItem && (


      <Grid item sm={12} md={4}>
      <ThemedPaper elevation={2} sx={{
        p: 2,
      }}>
      <Card variant='outlined' sx={{ marginBottom: 1 }}>
      <Box sx={{ p: 2 }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Typography gutterBottom variant='h5' component='div'>
      {isEdit ? 'Edit product' : 'Create a new product'}
      </Typography>
      <Typography gutterBottom variant='h6' component='div'>

      </Typography>
      </Stack>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
      <Stack direction='column' spacing={2}>
      <TextField
      value={editorState.title}
      label='Course Name' variant='filled' fullWidth
      error={!!editorState.title && (editorState.title.length > MAX_PRODUCT_NAME_LENGTH)}
      onChange={(e) => {
        const newState = structuredClone(editorState);
        const newTitle = e.target.value;
        newState.title = newTitle;
        console.log('course name changed', { newState }, newTitle);
        setEditorState(newState);
      }}
      />

      <Divider />

      {/* <input type='file' onChange={(e) => fileChange(e, idToken)} /> */}
      {/* <Uploader onComplete={() => { }}/> */}

      <FormControlLabel
      control={
        <Switch checked={editorState.isPublished}
        onChange={(e) => {
          const newState = structuredClone(editorState);
          const checkedValue = e.target.checked;
          newState.isPublished = checkedValue;
          setEditorState(newState);
        }}
        name='published' />
      }
      label='Publish live to the world?'
      />
      <Divider>Pricing</Divider>
      <Alert severity='info'>
      All prices are in US Dollars (USD).
      </Alert>

      <Stack direction='row' spacing={2}>
      <Box>

      <Typography variant='overline'>
      Subscription<br/>
      <sub>(Monthly)</sub>
      </Typography>
      <TextField
      label='Monthly Price' variant='filled' fullWidth
      value={UsdFormat.format(editorState.monthlyPrice ?? 9.67)}
      onFocus={(e) => {
        const target = e.target as HTMLInputElement;
        if (target.value.endsWith('.00')) {
          target.setSelectionRange(0, target.value.length - 3);
        }
      }}
      onChange={(e) => {
        const newState = structuredClone(editorState);
        let newPrice = (e.target.value.trim() || '0').replace(/[^0-9.]/g, '');
        if (newPrice.endsWith('.')) {
          newPrice = newPrice += '0';
        }
        newState.monthlyPrice = parseFloat(newPrice);
        newState.currency = 'USD';
        setEditorState(newState);
      }} />
      </Box>
      <Box>
      <Typography variant='overline'>
      Entire Course<br/>
      <sub>
      (One-time purchase)
      </sub>
      </Typography>
      <TextField
      value={UsdFormat.format(editorState.coursePrice ?? 99.00)}
      label='Entire Course' variant='filled' fullWidth
      onFocus={(e) => {
        const target = e.target as HTMLInputElement;
        if (target.value.endsWith('.00')) {
          target.setSelectionRange(0, target.value.length - 3);
        }
      }}
      onChange={(e) => {
        const newState = structuredClone(editorState);
        let newPrice = (e.target.value.trim() || '0').replace(/[^0-9.]/g, '');
        if (newPrice.endsWith('.')) {
          newPrice = newPrice += '0';
        }
        newState.coursePrice = parseFloat(newPrice);
        newState.currency = 'USD';
        setEditorState(newState);
      }} />
      </Box>
      {/* <Button
      color='info'
      variant='outlined' onClick={() => {

      }}>
      Add new price
    </Button> */}
    </Stack>
    <Divider>Actions</Divider>
    <Stack direction='column' spacing={2}>
    <LoadingButton variant='contained'
    loading={isUpdatingProduct || isCreatingProduct}
    disabled={!isValid}
    onClick={() => {
      const params = {
        idToken,
        productDetails: editorState,
      };
      const action = isEdit ? editProduct(params) : createProduct(params);
      dispatch(action).then(() => {
        if (!isEdit) {
          navigate(Paths.CREATIONS);
        } else {
          loadProduct(productId, dispatch);
        }
      });
    }}>
    Save
    </LoadingButton>
    </Stack>
    </Stack>
    <Divider sx={{ marginTop: 3}}>Course Content</Divider>
    <SortableList
    initialState={editorState as AnyObject}
    isSortingDisabled={!!activeItem}
    activeItem={activeItem}
    keyMapping={[
      ['id', '__originalId'], // <-- temporarily save `id` as another key.
      ['content', 'children'],
    ]}
    onChange={(newProductState: AnyObject) => {
      console.log('product state has changed from list', { newProductState });
      setEditorState(newProductState as ProductDatum);
    }}
    onGroupClick={(newState, groupIndex) => {
      console.log('group was clicked', newState, groupIndex)
    }}
    onItemClick={(newState, groupIndex, itemIndex) => {
      console.log('item was clicked', newState, groupIndex, itemIndex);
      setActiveItem({ groupIndex, itemIndex });
    }}
    />
    </Box>
    </Card>
    </ThemedPaper>
    </Grid>
  )}
  {(activeItem) && (
    <Grid item md={8} xs={12}>
    <ThemedPaper elevation={2} sx={{
      p: 2,
    }}>

    <Card sx={{
      p: 1,
      minHeight: 300,
    }}>
    {editorState && (<ProductItemEditor
      value={editorState && activeItem && getItemFromState(editorState, activeItem.groupIndex, activeItem.itemIndex)}
      onDoneClick={() => {
        setActiveItem(null);
      }}
      onChange={(newValue) => {
        console.log('ProductItemEditor', { editorState, newValue });
        if (!activeItem) {
          throw new Error('Active item missing');
        }
        const {
          groupIndex, itemIndex,
        } = activeItem;
        const newStateWithItem = setItemFromState(editorState, groupIndex, itemIndex, newValue);
        console.log('setting editor state', { newStateWithItem })
        setEditorState(newStateWithItem);
      }} />)}
      </Card>
      </ThemedPaper>
      </Grid>
    )}
    </Grid>
  );
}