import React, { useEffect } from 'react';
import { Box, Typography, LinearProgress, Grid, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { getAllCreations } from '@web-client/state/slices/creations';
import { AppDispatch, useAppSelector} from '@web-client/state/store';
import Paths from '@web-client/paths';
import { creationsSlice } from '@web-client/state/slices/creations';
import { ProductDatum } from '@web-client/services/ApiService';

const DUMMY_IMAGE = 'https://picsum.photos/seed/300/300';

const MyCreationsList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isGettingCreations =
    useAppSelector(creationsSlice.selectors.selectIsGettingCreations);
  const creations = useAppSelector(creationsSlice.selectors.selectCreations);


  useEffect(() => {
    dispatch(getAllCreations());
  }, [dispatch]);

  if (isGettingCreations) {
    return <LinearProgress variant='indeterminate' />;
  }

  // if (status === 'failed') {
  //   return (
  //     <Typography color="error" align="center">
  //       {error || 'Failed to load creations'}
  //     </Typography>
  //   );
  // }

  return (
    <Box sx={{ padding: 10 }}>
      <Typography variant="h1" className="gradient-text" gutterBottom>
        My Creations
      </Typography>

      <Grid container spacing={3}>
        {creations.length === 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <Card
              sx={{
                '&:hover': {
                  boxShadow: 6,
                  cursor: 'pointer',
                },
              }}
              onClick={() => {}}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  No creations yet.
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Create something to see it here.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        {creations.map((creation: ProductDatum) => (
          <Grid item xs={12} sm={6} md={4} key={creation.id}>
            <Card
              sx={{
                '&:hover': {
                  boxShadow: 6,
                  cursor: 'pointer',
                },
              }}
              onClick={() => navigate(Paths.PRODUCT_EDIT.replace(':productId', creation.id ?? ''))}
            >
              <CardContent>
                {/* <img
                  src={creation.imageUrl ?? DUMMY_IMAGE}
                  alt={creation.title}
                  loading="lazy"
                  style={{ width: '100%', height: 'auto' }}
                /> */}
                <Typography variant="h6" gutterBottom>
                  {creation.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {creation.creator?.displayName}
                </Typography>
                <Button
                  sx={{ marginTop: 2 }}
                  variant="contained"
                  onClick={() => navigate(Paths.PRODUCT_EDIT.replace(':productId', String(creation.id)))}
                >
                  Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MyCreationsList;