import {
  Card, Grid, List, ListItem, ListItemButton, ListItemText, Typography, Box, Divider, Collapse,
  LinearProgress,
  Button,
  Stack
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ThemedPaper from '../core/ThemedPaper';
import VideoPlayer from '../core/VideoPlayer';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@web-client/state/store';
import { getProductbyId, selectProduct } from '@web-client/state/slices/products';
import { ProductDatum } from '@web-client/services/ApiService';
import Paths from '@web-client/paths';


function Paywall(props: {
  product: ProductDatum,
}) {
  const { product } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const creatorId = product.creator?.uid ?? product.creator?.email as string;
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        width: 1,
        height: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        backdropFilter: 'blur(5px)',
        zIndex: 1,
        color: theme.palette.text.primary,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `radial-gradient(circle, ` +
            `${theme.palette.background.default}, ` +
            `transparent)`,
          zIndex: -1,
          backdropFilter: 'blur(10px)',
          opacity: 1,
        },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          position: 'relative',
          zIndex: 2,
          textShadow: `0 0 10px rgba(0, 0, 0, 0.5)`,
          background: `rgba(${theme.palette.background.paper}, 0.7)`,
          padding: theme.spacing(1, 2),
          borderRadius: theme.shape.borderRadius,
          userSelect: 'none'
        }}
      >
        Get Access Now!
      </Typography>
      {/* <Button
        variant="contained"
        color="primary"
        sx={{
          position: 'relative',
          zIndex: 2,
        }}
        onClick={() => {
          // navigate(Paths.SUBSCRIBE.replace(':userId', creatorId));
          if (product.id) {
            navigate(Paths.PRODUCT_CHECKOUT
              .replace(':productId', product.id)
              .replace(':action', 'subscribe'));
          }
        }}
      >
        Subscribe
      </Button> */}
      <Button
        variant="contained"
        color="secondary"
        sx={{
          position: 'relative',
          zIndex: 2,
        }}
        onClick={() => {
          if (product.id) {
            navigate(Paths.PRODUCT_CHECKOUT
              .replace(':productId', product.id)
              .replace(':action', 'purchase'));
          }
        }}
      >
        Buy Course
      </Button>
      {/* <pre>
        {JSON.stringify(product.creator, null, 4)}
      </pre> */}
    </Stack>
  );
}

const ProductCard = (params: { product: ProductDatum }) => {
  const { product } = params;
  const theme = useTheme();

  const [activeItem, setActiveItem] = useState<[number, number]>([0, 0]);
  const [expandedLists, setExpandedLists] = useState<number[]>([]);

  const handleListToggle = (index: number) => {
    setExpandedLists((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleItemClick = (groupIndex: number, itemIndex: number) => {
    setActiveItem([groupIndex, itemIndex]);
    if (!expandedLists.includes(groupIndex)) {
      setExpandedLists((prev) => [...prev, groupIndex]);
    }
  };

  const activeContent = product.content[activeItem[0]]?.content[activeItem[1]] ?? null;

  const isPaid = product.isPurchased;

  const videoSources = [
    {
      src: activeContent?.value,
      type: 'video/mp4',
    },
  ];

  return (
    <Card variant="outlined" sx={{ marginBottom: 1 }}>
      <Grid container spacing={2} sx={{ position: 'relative', minHeight: 'calc(100vh - 200px)' }}>
        <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
          <List sx={{ border: `1px solid ${theme.palette.divider}`, height: '100%' }}>
            {product.content.map((contentGroup, groupIndex) => (
              <List key={groupIndex} component="ol" sx={{ outline: `1px solid ${theme.palette.divider}` }}>
                <ListItem sx={{ mb: 2 }} onClick={() => handleListToggle(groupIndex)}>
                    <AutoStoriesIcon
                      sx={{
                        mr: 3,
                        ml: 2,
                        height: 30,
                        width: 30,
                        opacity: activeItem && activeItem[0] === groupIndex ? 0.5 : 0.2,
                      }}
                    />
                    <ListItemText primary={contentGroup.title} />
                </ListItem>
                <Divider />
                <Collapse in={expandedLists.includes(groupIndex)} timeout="auto" unmountOnExit>
                  <List component="ol" sx={{
                    backgroundColor: theme.palette.background.paper,
                  }}>
                    {contentGroup.content.map((content, index) => (
                      <ListItem key={index}>
                        <ListItemButton
                          selected={activeItem[0] === groupIndex && activeItem[1] === index}
                          onClick={() => handleItemClick(groupIndex, index)}
                        >
                          {index % 2 === 0 ? (
                            <CheckBoxIcon sx={{ mr: 3, opacity: 0.5 }} />
                          ) : (
                            <CheckBoxOutlineBlankIcon sx={{ mr: 3, opacity: 0.5 }} />
                          )}
                          <ListItemText primary={content.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </List>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={9} sx={{ paddingLeft: { md: '0 !important' } }}>
          {activeContent && (
            <Box sx={{ position: 'relative' }}>
              <ThemedPaper>
                <Typography variant="h5" sx={{ p: 2 }}>
                  {activeContent?.title}
                </Typography>
              </ThemedPaper>
              <Box sx={{ position: 'relative', minHeight: 'calc(50vh)' }}>
                {!isPaid && (
                  <Paywall product={product} />
                )}
                {activeContent?.type === 'video' && (
                  <div style={{ position: 'relative' }}>
                    <VideoPlayer sources={videoSources} onReady={() => { }} />
                  </div>
                )}
              </Box>
            </Box>
          )}
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <List sx={{ height: '100%', m: 0, p: 0 }}>
              {product.content.map((contentGroup, groupIndex) => (
                <List key={groupIndex} component="ol">
                  <ListItem sx={{ mb: 2 }}>
                    <ListItemButton onClick={() => handleListToggle(groupIndex)}>
                      <AutoStoriesIcon
                        sx={{
                          mr: 3,
                          ml: 2,
                          height: 30,
                          width: 30,
                          opacity: activeItem && activeItem[0] === groupIndex ? 0.5 : 0.2,
                        }}
                      />
                      <ListItemText primary={contentGroup.title} />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <Collapse in={expandedLists.includes(groupIndex)} timeout="auto" unmountOnExit>
                    <List component="ol">
                      {contentGroup.content.map((content, index) => (
                        <ListItem key={index}>
                          <ListItemButton
                            selected={activeItem[0] === groupIndex && activeItem[1] === index}
                            onClick={() => handleItemClick(groupIndex, index)}
                          >
                            {index % 2 === 0 ? <CheckBoxIcon sx={{ mr: 3 }} /> : <CheckBoxOutlineBlankIcon sx={{ mr: 3 }} />}
                            <ListItemText primary={content.title} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </List>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};


export default function ProductDetail() {
  const dispatch = useAppDispatch();
  const product = useAppSelector(selectProduct);
  const params = useParams();

  const { productId } = params;

  useEffect(() => {
    if (productId) {
      dispatch(getProductbyId({ productId }));
    }
  }, [dispatch, productId]);

  if (!product) {
    return (
      <Card variant="outlined" sx={{ marginBottom: 1 }}>
        <Box sx={{ p: 2 }}>
          <LinearProgress variant="indeterminate" />
        </Box>
      </Card>
    );
  }

  return (
    <ThemedPaper elevation={2} sx={{ m: 5 }}>
      <ProductCard product={product} />
    </ThemedPaper>
  );
}