import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Course }  from '@server/other/classes';
import ApiService from '@web-client/services/ApiService';


interface LearningCoursesState {
  courses: Course[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  filter: 'all' | 'purchased' | 'subscribed';
}

// Initial state
const initialState: LearningCoursesState = {
  courses: [],
  status: 'idle',
  error: null,
  filter: 'all',
};

// Async thunk to fetch courses
export const fetchLearningCourses =
  createAsyncThunk('courses/fetchLearningCourses', async () => {
    return await ApiService.getInstance().getLibrary() as Course[];
});

// Redux slice
export const coursesSlice = createSlice({
  name: 'learningCourses',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload; // Update the filter
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLearningCourses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLearningCourses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.courses = action.payload;
      })
      .addCase(fetchLearningCourses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch courses';
      });
  },
});

// Export actions and reducer
export const { setFilter } = coursesSlice.actions;
export default coursesSlice.reducer;
