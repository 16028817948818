import { createTheme } from '@mui/material/styles';

const monokaiTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#f92672', // Monokai pink for primary elements
    },
    secondary: {
      main: '#ae81ff', // Monokai purple for secondary elements
    },
    background: {
      default: '#1e1e1e', // Very dark background
      paper: '#252525', // Dark background for menus
    },
    text: {
      primary: '#f8f8f2', // Bright text
      secondary: '#75715e', // Muted text
    },
  },
  typography: {
    fontFamily: "'Sen', 'Helvetica Neue', Arial, sans-serif",
    fontWeightRegular: 200,
    fontWeightBold: 400,
    h1: {
      fontSize: '2.5rem',
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '2px',
      animation: 'toggleWhiteAndNeon 6s ease-in-out infinite', // New animation

    },
    h4: {
      fontSize: '1.5rem',
      letterSpacing: '2px',
      animation: 'toggleWhiteAndNeon 6s ease-in-out infinite', // New animation
    },
    body1: {
      letterSpacing: '2px',
    },
    body2: {
      letterSpacing: '1.5px',
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          '&.toolbar-menu': {
            backgroundColor: '#1e1e1e', // Flush background for toolbar menus
            color: '#f8f8f2', // Bright text
            boxShadow: 'none', // Remove shadow for toolbar menus
            borderRadius: 0, // No rounded corners
            padding: 0, // Remove extra padding
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.toolbar-menu-item': {
            backgroundColor: 'transparent', // No button-like background
            color: '#f8f8f2', // Bright text
            padding: '16px 24px', // Larger padding for XL items
            fontSize: '18px', // Larger font size for XL items
            borderBottom: '1px solid #252525', // Separator for toolbar items
            '&:last-child': {
              borderBottom: 'none', // Remove separator for the last item
            },
            '&:hover': {
              backgroundColor: '#3e3d32', // Subtle hover effect
            },
          },
        },
      },
    },
  },
});

// Add keyframes for the toggle animation
const globalStyles = `
@keyframes toggleWhiteAndNeon {
  0%, 100% {
    color: #f8f8f2; /* White */
    text-shadow: none;
  }
  25% {
    color: #f92672; /* Monokai pink */
    text-shadow: 0 0 8px #f92672;
  }
  50% {
    color: #fd971f; /* Monokai orange */
    text-shadow: 0 0 8px #fd971f;
  }
  75% {
    color: #66d9ef; /* Monokai cyan */
    text-shadow: 0 0 8px #66d9ef;
  }
}

.gradient-text {
  animation: toggleWhiteAndNeon 6s ease-in-out infinite;
  font-weight: bold;
}
`;

// Dynamically append global styles for the animation
// const styleSheet = document.createElement('style');
// styleSheet.type = 'text/css';
// styleSheet.innerText = globalStyles;
// document.head.appendChild(styleSheet);

export default monokaiTheme;
