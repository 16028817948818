import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { fetchSubscriptions } from '@web-client/state/slices/subscriptions';
import { Box, Typography, LinearProgress, Grid, Card, CardContent } from '@mui/material';
import { AppDispatch, RootState } from '@web-client/state/store';
import { useNavigate } from 'react-router-dom';
import Paths from '@web-client/paths';

interface SubscriptionsProps {}

const Subscriptions: React.FC<SubscriptionsProps> = ({

}) => {
  const dispatch = useDispatch<AppDispatch>();
  // const { subscriptions, status, error } = useSelector((state: RootState) => state.subscriptions);

  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(fetchSubscriptions());
  // }, [dispatch, userId]);

  // if (status === 'loading') {
  //   return (<LinearProgress variant='indeterminate' />);
  // }

  // if (status === 'failed') {
  //   return (
  //     <Typography color="error" align="center">
  //       {error || 'Failed to load subscriptions'}
  //     </Typography>
  //   );
  // }

  return (
    <Box sx={{ padding: 10 }}>
      <Typography variant="h1" className="gradient-text" gutterBottom>
        My Subscriptions
      </Typography>

      <Grid container spacing={3}>
        {/* {subscriptions.map((subscription) => (
          <Grid item xs={12} sm={6} md={4} key={subscription.id}>
            <Card
              sx={{
                '&:hover': {
                  boxShadow: 6,
                  cursor: 'pointer',
                },
              }}
              onClick={() => {

              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {subscription.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {subscription.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))} */}

        <Grid item xs={12} sm={12} md={12}>
            <Card
              sx={{
                '&:hover': {
                  boxShadow: 6,
                  cursor: 'pointer',
                },
              }}
              onClick={() => {

              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  No subscriptions yet.
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Subscribe to a course to see it here.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
      </Grid>
    </Box>
  );
};



export default Subscriptions;