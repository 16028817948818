import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import assert from 'assert';
import {typeCheck} from 'type-check';
import ApiService from '../../services/ApiService';
import { AnyObject } from '@web-client/types';

export interface ContentSliceState {
  isGettingContent: boolean,
  videoInfo: AnyObject,
}

const initialState: ContentSliceState = {
  isGettingContent: false,
  videoInfo: {},
};

export const getVideoInfo = createAsyncThunk(
  'content/getVideoInfo', async (params: {
    videoId: string,
  }) => {
    const { videoId } = params;
    assert(typeCheck('String', videoId), 'idToken must be string');
    const result = await ApiService.getInstance()
      .getVideoInfo(encodeURIComponent(videoId));
    return result;
});

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: (create) => ({

  }),
  extraReducers: (builder) => {
    // ------------ Get Video Info By ID ---------------
    builder.addCase(getVideoInfo.pending, (state, action) => {
      state.isGettingContent = true;
    }).addCase(getVideoInfo.fulfilled, (state, action) => {
      state.isGettingContent = false;
      console.log('action.payload', action.payload)
      if (action.payload) {
        const videoInfo = { ...state.videoInfo };
        videoInfo[action.payload?.filePath] = action.payload;
        state.videoInfo = videoInfo;
      }
    }).addCase(getVideoInfo.rejected, (state, action) => {
      state.isGettingContent = false;
    });
  },
  selectors: {
    selectVideoInfo: (state) => state.videoInfo,
  },
});

export const {
  selectVideoInfo,
} = contentSlice.selectors;

export const {

} = contentSlice.actions;

export default contentSlice.reducer;