import { Button, CircularProgress, Paper, styled, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../state/store";
import {
  userSlice, getOrderHistory,
  getProfile,
} from "../../state/slices/user";
import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";


const locale = 'en-US'; // TODO(benedictchen): Get this from the user's profile.


export default function OrderHistory() {
  const user = useAppSelector(userSlice.selectors.selectUser);
  const isGettingOrderHistory =
    useAppSelector(userSlice.selectors.isGettingOrderHistory);
  const orders = useAppSelector(userSlice.selectors.selectOrders);

  const dispatch = useAppDispatch();

  useEffect(() => {
      dispatch(getProfile())
      dispatch(getOrderHistory())
  }, [dispatch]);

  if (!user || isGettingOrderHistory) {
    return <CircularProgress />;
  }

  return (
    <Paper
      elevation={5}
      sx={{
        p: 10,
        m: 10,
      }}
    >
      {(orders && orders.length === 0) ? <div>No transactions found</div> : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders && orders.map((order) => {
                const formattedAmount = new Intl.NumberFormat(locale, {
                  style: 'currency',
                  currency: order.currency,
                }).format(order.amount / 100);
                return (
                  <TableRow key={order.id}>
                    <TableCell>{new Date(order.created * 1000).toLocaleString()}</TableCell>
                    <TableCell>{order.description}</TableCell>
                    <TableCell>{formattedAmount}</TableCell>
                    <TableCell>{order.status}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          window.open(order.receipt_url ?? '', '_blank');
                        }}
                      >
                        View Reciept
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            }
            </TableBody>
          </Table>
        </TableContainer>
      )}

    </Paper>
  );
}