import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StorageState = {

};

const initialState: StorageState = {
};

export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    storageChanged: (state, action: PayloadAction<StorageState>) => {
      console.log('storage changed in reducer', state, action);
    },
  },
});

export const { storageChanged } = storageSlice.actions;
export default storageSlice.reducer;
