import byteSize from 'byte-size';
import moment from 'moment';
import { Box, Button, Grid, LinearProgress, } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../state/store';
import { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridSlots } from '@mui/x-data-grid';

import {
  getUploads, selectIdToken, selectIsGettingUploads,
  selectUploads,
} from '../../state/slices/user';
import { debounce } from 'lodash';
import ThemedPaper from '../core/ThemedPaper';
import VideoPlayer from '../core/VideoPlayer';
import GridListToolbar from './GridListToolbar';
import Uploader from './Uploader';
import { getVideoInfo, selectVideoInfo } from '../../state/slices/content';

const DEBOUNCE_WAIT_MS = 1;

const loadUploads = debounce((idToken, dispatch) => {
  return dispatch(getUploads());
}, DEBOUNCE_WAIT_MS);


const columns: GridColDef[] = [
  {
    field: 'fileName',
    headerName: 'File Name',
    valueGetter: (value, row) => {
      return value;
      // `${row.name.replace(/^.*\[::\][-]?/, '')}`;
    },
    minWidth: 500,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    valueGetter: (value, row) => {
      const updatedTime = row.updated;
      if (!updatedTime) {
        return 'Unknown';
      }
      return moment(updatedTime).fromNow();
    },
  },
  {
    field: 'size',
    headerName: 'Size',
    valueGetter: (value, row) => byteSize(row.metadata.size),
  },
];

export default function VideoItemEditor({
  value = '',
  onChange = (value: string) => {},
}) {
  const dispatch = useAppDispatch();
  const idToken = useAppSelector(selectIdToken) ?? '';
  const userUploads = useAppSelector(selectUploads);
  const isGettingUploads = useAppSelector(selectIsGettingUploads);
  const [isUserUploading, setIsUserUploading] = useState(false);

  // if there is a value, let's check if there is an hls version of the video
  // either in gcp or in api.video. If not, we'll just show the video as is.
  const cleanedValue = value &&
  /\/(\w+\/videos\/.*)/.exec(decodeURIComponent(value))?.[1].split('?')[0];
  const videoInfo = useAppSelector(selectVideoInfo);
  let gcsValue = cleanedValue && videoInfo[cleanedValue]?.gcsM3u8PublicUrl;
  gcsValue = gcsValue && decodeURIComponent(gcsValue);
  const videoUrl = gcsValue ?? value;

  useEffect(() => {
    if (cleanedValue) {
      dispatch(getVideoInfo({ videoId: cleanedValue }));
    }
  }, [cleanedValue, dispatch]);

  useEffect(() => {
    if (idToken && !videoUrl) {
      loadUploads(idToken, dispatch);
    }
  }, [idToken, dispatch, videoUrl]);


  const videoSources = [
    (gcsValue && {
      src: videoUrl,
      type: 'application/x-mpegURL',
    }),
    {
      src: value,
      type: 'video/mp4',
    },
    {
      src: value,
      type: 'video/webm',
    },
    {
      src: value,
      type: 'video/ogg',
    },
  ].filter((v) => !!v)

  return (
    <ThemedPaper sx={{ p: 3 }} elevation={3}>
    {isGettingUploads && <LinearProgress />}
    <Box></Box>
    {(videoUrl) ? (
      <>
      <Button
      sx={{ marginBottom: 5 }}
      variant='outlined'
      onClick={() => {
        onChange('');
      }}>
      Pick a different video
      </Button>
      <Box>
      <Grid container
      direction='row'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
      <ThemedPaper elevation={5} sx={{ minWidth: 300}}>

      <VideoPlayer sources={videoSources}
      fluid
      // height={window.innerHeight / 2}
      />

      </ThemedPaper>
      </Grid>
      </Grid>
      </Box>

      </>
    ):
    isUserUploading ? (
      <Uploader
      onCancelClick={() => setIsUserUploading(false)}
      onComplete={() => {
        setIsUserUploading(false);
        // getUserUploads(idToken);
      }} />
    ) : (
      <DataGrid
      initialState={{
        sorting: { sortModel: [{ field: 'updated', sort: 'desc' }] },
      }}
      slots={{
        toolbar: () => <GridListToolbar onUploadClick={() => setIsUserUploading(true)}/>,
        loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
      }}
      getRowId={(row) => { return row.id }}
      columns={columns}
      rows={userUploads ?? []}
      sx={{ cursor: 'pointer', minHeight: 300}}
      onRowClick={function ({ row }) {
        console.log('user selected this item', { row });
        const videoUrl = row.url;
        onChange(videoUrl);
      }}/>
    )}
    </ThemedPaper>)
    ;
  }
