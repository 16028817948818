
import { store } from './state/store';
import { storageChanged } from './state/slices/storage';
import { Provider } from 'react-redux';
import {
  RouterProvider,
} from 'react-router-dom';
import { ContentWrapper } from './components/ContentWrapper';
import { Notifications } from './components/notifications/Notifications';
import { ThemeProvider } from '@mui/material';
import {
  createBrowserRouter,
} from 'react-router-dom';
import ProtectedRoute from './components//ProtectedRoute';
import LoginModal from './components/Login';
import Checkout from './components/payments/Checkout';
import CheckoutComplete from './components/payments/CheckoutComplete';
import UserProfile from './components/account/UserProfile';
import ProductListing from './components/products/ProductListing';
import ProductDetail from './components/products/ProductDetail';
import ProductEditor from './components/products/ProductEditor';
import MyCreationsList from './components/products/MyCreationsListing';
import Home from './components/Home';
import './App.css';
import Paths from './paths';
import OrderHistory from './components/account/Orders';
import Library from './components/products/Library';
import monokaiTheme from './themes/monokai';
import { useEffect } from 'react';
import Subscriptions from './components/Subscriptions';




const router = createBrowserRouter([
  {
    path: Paths.ROOT,
    element: <Home />,
    children: [
      {
        path: '',
        element: <ProductListing/>,
      },
    {
        path: Paths.PRODUCT_DETAILS,
        element: <ProductDetail/>,
      },
    ],
  },
  {
    path: Paths.HOME,
    element: <ProtectedRoute><Home /></ProtectedRoute>,
    children: [
      {
        path: Paths.LIBRARY,
        element: <Library/>,
      },
      {
        path: Paths.SUBSCRIPTIONS,
        element: <Subscriptions/>,
      },
      {
        path: Paths.CREATIONS,
        element: <MyCreationsList/>,
      },
      {
        path: Paths.PRODUCT_EDIT,
        element: <ProductEditor isEdit={true} />,
      },
      {
        path: Paths.PRODUCT_LISTING_LOGGED_IN,
        element: <ProductListing />,
      },
      {
        path: Paths.PRODUCT_CREATE,
        element: <ProductEditor />,
      },

      {
        path: Paths.PROFILE,
        element: <UserProfile/>,
      },
      {
        path: Paths.PRODUCT_CHECKOUT,
        element: <Checkout/>,
      },
      {
        path: Paths.SUBSCRIBE,
        element: <Checkout/>,
      },
      {
        path: Paths.PRODUCT_CHECKOUT_COMPLETE,
        element: <CheckoutComplete/>,
      },
      {
        path: Paths.ORDERS,
        element: <OrderHistory/>,
      },
    ]
  },
  {
    path: Paths.LOGIN,
    element: <LoginModal open={true}/>,
  },
]);

const storageListener = (event: StorageEvent) => {
  console.log('storage event', event);
  store.dispatch(storageChanged({
    key: event.key,
    value: event.newValue,
  }));
};

function App() {
  // useEffect(() => {
  //   document.querySelector('html')?.setAttribute('data-theme', 'dim');
  // });

  useEffect(() => {
    window.addEventListener('storage', storageListener);
    return () => {
      window.removeEventListener('storage', storageListener);
    };
  },
  []);

  return (
    <Provider store={store}>
    <ThemeProvider theme={monokaiTheme}>
    <ContentWrapper>
    <RouterProvider router={router} />
    <Notifications/>
    </ContentWrapper>
    </ThemeProvider>
    </Provider>
  );
}

export default App;
